import React from 'react';
import "./Development.scss";
import {sameStackCompanies, technologies, wordpressVsReact} from '../../services/local/development'

function Development() {

  return (
    <section className='development'>
      <div className="same-stack-companies">
        <h1 className="title orbitron">Compañías que adoptan nuestro stack tecnológico:</h1>
        <div className="same-stack-companie-cards">
          {
            sameStackCompanies.map((name, i)=> <div className="same-stack-companie-card" key={i}>{name}</div>)
          }
        </div>
      </div>
      <div className="technologies">
        <h1 className="title orbitron">Ventajas que aporta la utilización de nuestro software:</h1>
        <div className="tech-cards">
          {
            technologies.map((technologie, i)=> {
              return <div className="tech-card" key={i}>
                <img src={technologie.logoSrc} alt="" className="card-logo"/>
                <h3 className="orbitron"><b>{technologie.name}</b></h3>
                <ul className="card-text">
                  {
                    technologie.advantages.map((advantage, i)=> <li key={i}><b>{advantage.name}</b> {advantage.text}</li>)
                  }
                </ul>
              </div>
            })
          }
        </div>
      </div>
      <div className="comparation">
        <h1 className="title orbitron">¿Qué diferencia existe entre las paginas web profesionales como las creadas con REACT y las generadas por CMS como Wordpress?</h1>
        <h2>Existen varias diferencias, estas son algunas de las principales ventajas de utilizar React en lugar de WordPress:</h2>
        <ul>
          {
            wordpressVsReact.map((difference, i)=> <li key={i}><b>{difference.name} </b>{difference.text}</li>)
          }
        </ul>
        <h3><b>En conclusión, React es una tecnología más avanzada y potente que WordPress, especialmente para la creación de páginas web interactivas y escalables.</b></h3>
      </div>
    </section>
  );
}
 
export default Development;