import React from 'react';
import './Footer.scss';
import whiteStroomLogo from '../../Assets/logo/stroom-white-transparent-horizontal.png'

function Footer() {
 
	return (
		<footer>

			<div className="footer-logo">
				<img src={whiteStroomLogo} alt="" />
			</div>
			<div className="footer-contact">
				<h3>Contacto</h3>
				<p>Lunes a Jueves: de 10h a 15h (CEST)</p>
				<p>stroomsite@gmail.com</p>
				{/* <p>teléfono: 915 632 356</p> */}
				<p>Pº Acacias 50, Madrid 28005</p>
			</div>
			<div className="footer-policy">
				<p>© Stroom 2021</p>
				<a href="/privacy-policy" target="_blank">Política de privacidad y cookies</a>
			</div>
		</footer>
	);
}
 
export default Footer;