import React, {useEffect, useState} from 'react';
import './StroomInputNumber.scss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

function StroomInputNumber({setNumber, disabled, currentUnits}) {

    const [number, setnumber] = useState(1)

    useEffect(()=> {
        setNumber(number)
    },[number, setNumber])

    useEffect(()=> {
        setnumber(currentUnits ?? 1)
    },[currentUnits, setnumber])

    return (
        <div className={disabled ? "disabled-stroom-input-number" : "stroom-input-number"}>
            <div className="icon-button" onClick={()=> {if(!disabled)  setnumber(prev => prev+1)}}><AddCircleOutlineIcon color="success"></AddCircleOutlineIcon></div>
            <div className="icon-button" onClick={()=> {if(!disabled) setnumber(prev => prev>2 ? prev-1 : 1)}}><RemoveCircleOutlineIcon color="error"></RemoveCircleOutlineIcon></div>
            <div className="number">{number}</div>
        </div>
    );
}

export default StroomInputNumber;
