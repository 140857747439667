import React from 'react';
import './Burger.scss';

function Burger({ open, setOpen }) {
  return (
    <nav className='burger' open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </nav>
  );
}

export default Burger;
