import React, { useState, useEffect } from 'react';
import Burger from '../Burger/Burger';
import Logo from '../../Assets/logo/stroom-transparent-horizontal.png';
import Menu from '../Menu/Menu';
import './Header.scss';
import { useHistory, useLocation  } from 'react-router-dom';

function Header() {
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const [isExcludedUrl, setisExcludedUrl] = useState(false)
  const excludedUrl = ['/privacy-policy']

  useEffect(() => {
      setisExcludedUrl(excludedUrl.includes(location.pathname))
  },[setisExcludedUrl, location, excludedUrl])


  return (
    <header>
      <img onClick={() => {history.push('/'); setOpen(false)}} src={Logo} alt='logo-stroom'></img>
      {!isExcludedUrl&&<div>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>}
    </header>
  );
}

export default Header;
