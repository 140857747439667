import React from "react";
import "./Home.scss";
import { useHistory } from "react-router-dom";
// import decoBannerImg from "../../Assets/decoration/homeBanner-img.png";
import tickImg from "../../Assets/icons/icon-stroom-list.svg";
import decoFeatureImg from "../../Assets/decoration/feature-deco-img.png";

import StraightenIcon from "@mui/icons-material/Straighten";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import ConstructionIcon from "@mui/icons-material/Construction";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import StroomSlashButton from "../../Components/StroomSlashButton/StroomSlashButton"

function Home() {
    const history = useHistory();
    const bannerBoxes = ["Pide tu presupuesto"];
    const leanSteps = [
        { title: "Aprender", img: <LocalLibraryIcon color='info' />, text: "Con los datos recopilados sobre la empresa, usuarios y servicio, aprendemos y modificamos los puntos necesarios." },
        { title: "Medir", img: <StraightenIcon color='info' />, text: "Una vez que la web esté funcionando, analizamos el uso de la web para que se ajuste a lo que el usuario y tú esperáis." },
        { title: "Construir", img: <ConstructionIcon color='info' />, text: "Creamos de forma rápida el producto mínimo viable (PMV) con las características suficientes para lanzar tu proyecto al mercado." },
        { title: "Mejorar", img: <SelfImprovementIcon color='info' />, text: "Trazamos el plan para implementar los cambios y mejoras necesarias de manera rápida y eficiente." },
    ];
    const featureList = [
        { title: "Web SEO friendly", text: "Optimización para motores de búsqueda, integración con redes sociales y mucho más." },
        { title: "Web UX", text: "Ofrece a tus clientes una excelente experiencia de usuario para que te garantice alcanzar tus objetivos comerciales." },
        { title: "Web Personalizada", text: "Trabajamos en estrecha colaboración contigo para asegurarnos de que tu web sea un reflejo exacto de tu negocio." },
        { title: "Web responsive", text: "Nos aseguramos de que todos tu sitio web se adapte a todos los dispositivos." },
    ];

    const homeBanner = (
        <div className='home-section home-banner gradient'>
            <h1 className='orbitron'>Stroom</h1>
            <h2 className='orbitron'>Servicios Profesionales de Diseño y Desarrollo Web</h2>
            <div className='banner-box-container'>
                {bannerBoxes.map((bannerBox, i) => (
                    <StroomSlashButton key={i} buttontext={bannerBox} onClickFunction={()=> history.push("/estimation")}></StroomSlashButton>
                ))}
            </div>
            {/* <div className='banner-deco-img'>
				<img src={decoBannerImg} alt='decoration images from Stroom'></img>
			</div> */}
        </div>
    );
    const webFeatures = (
        <div className='home-section home-Features'>
            <h2>Tu solución completa para tener presencia en internet</h2>
            <p>Ofrecemos una amplia gama de servicios de desarrollo web personalizado para alcanzar tus objetivos.</p>
            <div className='features'>
                {featureList.map((feature, i) => (
                    <div className='feature' key={i}>
                        <img src={tickImg} alt='' />
                        <h3 className='orbitron'>{feature.title}</h3>
                        <p>{feature.text}</p>
                    </div>
                ))}
            </div>
            <div className='feature-deco-img'>
                <img src={decoFeatureImg} alt='decoration images from Stroom'></img>
            </div>
        </div>
    );
    const leanCicle = (
        <div className='home-section lean-cicles-container'>
            <h2>Trabajamos con metodologías ágiles</h2>
            <div className='lean-cicles'>
                {leanSteps.map((elem, i) => (
                    <div className={"cicle cicle" + i} key={i}>
                        <div className='circle-icon'>{elem.img}</div>
                        <h4 className='orbitron'>{elem.title}</h4>
                        <p>{elem.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );

    const parallax = (
        <div className='home-section parallax'>
            <h2 className='orbitron'>Mejora continua de tu web</h2>
        </div>
    );

    const webCtaBudget = (
        <div className='home-section home-Budget'>
            <h2>Tu sitio web totalmente personalizado, adaptable y escalable.</h2>
            <p>Sabemos que cada negocio es único y tiene diferentes necesidades. Por eso, te ofrecemos la posibilidad de seleccionar el tipo de web que mejor se adapte al tuyo.</p>
            <p>Tenemos todo tipo de opciones, desde páginas personales o landings, webs para startups o PYMES hasta grandes empresas.</p>
            <p>Obtén tu presupuesto ahora.</p>
            <div className='banner-box-container'>
                <StroomSlashButton buttontext={"Pide tu presupuesto"} onClickFunction={()=> history.push("/estimation")}></StroomSlashButton>
            </div>
        </div>
    );

    const contactButton = (
        <div className='home-section home-contact gradient'>
            <h3>¡Deja que tu negocio crezca con nosotros!</h3>
            <div className='banner-box-container'>
                <StroomSlashButton buttontext={"Contactar"} onClickFunction={()=> history.push("/contact")} otherStyle={"secondary-style"}></StroomSlashButton>
            </div>
        </div>
    );

    return (
        <section className='view-home'>
            {homeBanner}
            {webFeatures}
            {leanCicle}
            {parallax}
            {webCtaBudget}
            {contactButton}
        </section>
    );
}

export default Home;
