import React  from 'react';
import { useHistory } from 'react-router-dom';
import './Menu.scss';

function Menu({ open , setOpen}) {
  const history = useHistory();

  return (
    <div className={open ? 'menu open' : 'menu'} onMouseLeave={()=> setOpen(false )}>
        <span onClick={() => {history.push('/branding'); setOpen(false)}}>Branding</span>
        <span onClick={() => {history.push('/development'); setOpen(false)}}>Desarrollo web</span>
        <span onClick={() => {history.push('/contact'); setOpen(false)}}>Contact</span>
    </div>
  );
}

export default Menu;
