import React from 'react';
import './EstimationSteps.scss';

export function EstimationSteps(props) {

    return (
        <div className="stepper">
            <div className="steps">
                {
                    props.steps.map((step,i) =>
                        <div className="step" style={{width: (100 / props.steps.length) +'%'}} key={i}>
                            <p style={props.currentStep===i ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}>{step}</p>
                        </div>
                )}
                <div style={{width: (100 / props.steps.length) * props.currentStep +'%'}} className={'progress-bar'}></div>
            </div>
        </div>
    );
}