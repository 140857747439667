import { Dialog } from '@mui/material';
import React, { useState, useEffect } from 'react';
import StroomToggleButton from '../StroomToggleButton/StroomToggleButton';
import './StroomCookiesModal.scss';
import { useLocation } from 'react-router-dom';

function StroomCookiesModal() {
    const location = useLocation();

    const [showCookies, setshowCookies] = useState(!localStorage.getItem('stroomCookiesAcceptance'))
    const [currentSelection, setcurrentSelection] = useState(['functional'])

    const [isExcludedUrl, setisExcludedUrl] = useState(false)
    const excludedUrl = ['/privacy-policy']

    useEffect(() => {
        setisExcludedUrl(excludedUrl.includes(location.pathname))
    },[setisExcludedUrl, location, excludedUrl])

    if(!!isExcludedUrl){
        return <></>
    }else{
        return (
            <Dialog open={showCookies}>
                <div className="stroom-cookies-dialog-content">
                    <h2>Cookies y política de privacidad</h2>
                    <p>Utilizamos cookies propias para el correcto funcionamiento de nuestros servicios, además
                        analizamos la navegación realizada, para posteriormente mejorar técnicamente la página. 
                    </p>
                    <div className="cookies-options">
                        <div className="cookies-option">
                            <p>Funcional y política de privacidad</p>
                            <StroomToggleButton
                                        class="stroom-toggle-button-flex" 
                                        defaultChecked={true} 
                                        isDisabled={true}
                                        disableCounter={true}
                                    >
                            </StroomToggleButton>
                        </div>
                        <div className="cookies-option">
                            <p>Marketing</p>
                            <StroomToggleButton
                                        class="stroom-toggle-button-flex" 
                                        extraSelected={() => setcurrentSelection(prev => {
                                            const currentSelection = 'marketing'
                                            if(prev.includes(currentSelection)){
                                                return prev.filter(elem => elem !==currentSelection)
                                            }else{
                                                return [...prev, currentSelection]
                                            }
                                        })}
                                        defaultChecked={false} 
                                        disableCounter={true}
                                    >
                            </StroomToggleButton>
                        </div>
                    </div>
                    <div className="choose-options-box">
                        <button className="shadow-button-sliding" onClick={() => changeCookiesState(['functional', 'marketing'])}>Aceptar todo</button>
                        <p className="selected-acceptance-link" onClick={() => changeCookiesState(currentSelection)}>Aceptar seleccionados</p>
                    </div>
                    <div className="privacy-policy">
                        <a href="/privacy-policy" target="_blank">Política de privacidad y cookies</a>
                    </div>
                </div>
            </Dialog>
        ) 
    }

    function changeCookiesState(state) {
        setshowCookies(!state)
        localStorage.setItem('stroomCookiesAcceptance', state);
    }
}

export default StroomCookiesModal;
