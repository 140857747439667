import {preOrderPath} from '../config/endpoints';
import {track} from '../services/segment-service'

export const getOrders = ()=>{
    return fetch(preOrderPath).then((res) => res.json());
}

export const newOrder = async (order) =>{
    track('Pedido Solicitado', {order})
    const response = await fetch(preOrderPath, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(order),

    }).then((res) => res.json())
    
    return response.status===200;
}