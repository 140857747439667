import platform from 'platform';


export const getDeviceData = () =>{
    return {
        deviceInfo: {
            browser:platform.name,
            version : platform.version,
            os : platform.os.toString(),
            device : platform.product
        }
    }
}

export const page = (where) => {
    window.analytics['page'](where, getDeviceData())
}

export const track = (name, payload) => {
    window.analytics['track'](name, {...payload, ...getDeviceData()})
}