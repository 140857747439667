import React, { useState, useEffect }from 'react';
import './global.scss';
import {
    BrowserRouter,
    Switch,
    Route
  } from "react-router-dom";
  

  //Views
  import Home from './Views/Home/Home.jsx';
  import Contact from './Views/Contact/Contact.jsx';
  import Development from './Views/Development/Development.jsx';
  import Branding from './Views/Branding/Branding.jsx';
  import Estimation from './Views/Estimation/Estimation.jsx';
  import PrivacyPolicy from './Views/PrivacyPolicy/PrivacyPolicy.jsx';
  import NotFound from './Views/NotFound/NotFound.jsx';
//   import WOW from 'wowjs'
  import Header from './Components/Header/Header.jsx';
  import StroomCookiesModal from './Components/StroomCookiesModal/StroomCookiesModal.jsx';
  import Footer from './Components/Footer/Footer.jsx';
  import Poster from './Components/Poster/Poster.jsx';
  import {track} from './services/segment-service';


  function App (){
    const [showMainWeb, setshowMainWeb] = useState(!!localStorage.getItem('stroomVisited'))
    const [showPoster, setshowPoster] = useState(!localStorage.getItem('stroomVisited'))
  

    useEffect(() => {
        // new WOW.WOW().init();
        if(showPoster){
            track('Nuevo Usuario', {
                info: {}
            });
        }
    })

    useEffect(() => {
        if(showMainWeb){
            document.querySelector('main').style.display = 'unset';
        }
    }, [showMainWeb])

    const transitionToHome = () => {
        setshowMainWeb(true)
        setTimeout(()=> {
            localStorage.setItem('stroomVisited', true)
            setshowPoster(false)
        }, 2000)
    }
    
    return(
        <>
            <title>Stroom</title>
            <meta 
                name="description" 
                content="Stroom desarrolla páginas web profesionales de alta calidad 
                para empresas de todos los tamaños. Ofrecemos servicios de diseño personalizado, desarrollo web 
                y optimización SEO para ayudar a aumentar la visibilidad y el rendimiento de tu sitio web en los 
                motores de búsqueda. ¡Contáctanos hoy para obtener tu presupuesto!">
            </meta>
            <link rel="canonical" href="https://www.stroomsite.es" />
            { showPoster && <Poster transitionToHome={() => transitionToHome()}/>}
            { showMainWeb && 
            <main>
            <BrowserRouter>
            { !showPoster && <Header />}
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/contact" component={Contact} />
                <Route path="/development" component={Development} />
                <Route path="/branding" component={Branding} />
                <Route path="/estimation" component={Estimation} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route component={NotFound} />
            </Switch>
            <StroomCookiesModal></StroomCookiesModal>
            </BrowserRouter>
            <Footer />
            </main>
            }
        </>
    )
 }
export default App;


