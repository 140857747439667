import React from 'react';
import './Contact.scss';

function Contact() {
 
  return (
    <section className="view-contact">
      <div className='contact-section schedule'>
        <h1><b>Horario de atención al cliente</b></h1>
        <h2>Lunes a Jueves: de 10h a 15h (CEST)</h2>
        <h3>Puede contactarnos a través de nuestro email <b>stroomsite@gmail.com</b></h3>
      </div>

      <div className='contact-section'>
        <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.308694319845!2d-3.7148111235418924!3d40.40201175653421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4227cfe45de18b%3A0x7186f115e61d45d4!2sP.%C2%BA%20de%20las%20Acacias%2C%2050%2C%2028005%20Madrid!5e0!3m2!1ses!2ses!4v1675550486836!5m2!1ses!2ses" 
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
      {/* <div className='contact-section'>
        <h2>Número de teléfono</h2>
      </div> */}
      {/* <div className='contact-section'>
        <h2>Enlaces a las redes sociales</h2>
      </div> */}
      {/* <div className='contact-section'>
        <h2>Formulario de contacto</h2>
      </div> */}
    </section>
  );
}
 
export default Contact;