import reactLogo from '../../Assets/images/tech/react.png'
import mongoLogo from '../../Assets/images/tech/mongoDB.png'
import nodeLogo from '../../Assets/images/tech/node.png'
import scssLogo from '../../Assets/images/tech/scss.png'

export const sameStackCompanies = [
    'Netflix',
    'Uber',
    'Airbnb',
    'Microsoft',
    'Twitter',
    'Facebook',
]

export const wordpressVsReact = [
    {name: 'Interactividad:', text: 'React permite crear aplicaciones web interactivas y dinámicas, mientras que WordPress se enfoca principalmente en la creación de sitios estáticos. Con React, se pueden crear páginas web con características como actualizaciones en tiempo real, animaciones y efectos visuales.'},
    {name: 'Velocidad:', text: 'Las páginas web creadas con React suelen ser más rápidas que las hechas con WordPress, ya que React se basa en JavaScript, un lenguaje de programación rápido y eficiente. Además, React utiliza una técnica llamada "virtual DOM" que permite actualizar solo las partes de la página que han cambiado, lo que también contribuye a mejorar la velocidad.'},
    {name: 'Personalización:', text: 'React permite una mayor flexibilidad y personalización en la creación de páginas web. Se pueden crear componentes reutilizables y adaptarlos a las necesidades específicas de cada proyecto. En cambio, WordPress tiene una estructura más rígida y limitada en cuanto a personalización.'},
    {name: 'Escalabilidad:', text: 'React permite crear aplicaciones web escalables, lo que significa que se pueden adaptar fácilmente a un mayor número de usuarios y tráfico. WordPress, por otro lado, puede tener problemas de rendimiento con un gran número de usuarios y tráfico.'},
    {name: 'Facilidad de uso:', text: 'WordPress es conocido por su facilidad de uso y su gran variedad de plantillas y plugins disponibles. Sin embargo, React es una tecnología más avanzada y requiere más conocimientos técnicos para ser utilizada.'}
]

export  const technologies = [
    {
      name: 'React.js', 
      logoSrc: reactLogo, 
      advantages: [
        {name:'Mantenimiento y escalabilidad: ', text: 'React permite crear componentes pequeños y reutilizables.'},
        {name:'Interoperabilidad: ', text: 'React es compatible con otras librerías y frameworks.'},
        {name:'Rendimiento: ', text: 'React solo re-renderiza los componentes que cambian, lo que mejora el rendimiento de la aplicación.'},
        {name:'Amplia comunidad: ', text: 'React cuenta con una amplia comunidad de desarrolladores que proporcionan recursos y soluciones.'}
      ]
    },
    {
      name: 'Node.js', 
      logoSrc: nodeLogo, 
      advantages: [
        {name:'Escalabilidad: ', text: 'Node.js permite crear aplicaciones escalables y de alta concurrencia gracias a su arquitectura non-blocking I/O.'},
        {name:'Velocidad: ', text: 'Node.js es rápido debido a que está construido sobre el motor JavaScript V8 de Google Chrome.'},
        {name:'Variedad de librerías: ', text: 'Node.js cuenta con una amplia variedad de librerías y paquetes en su repositorio oficial (npm).'}
      ]
    },
    {
      name: 'MongoDB', 
      logoSrc: mongoLogo, 
      advantages: [
        {name:'Escalabilidad: ', text: 'Es altamente escalable, lo que permite manejar grandes volúmenes de datos y alta concurrencia.'},
        {name:'Flexibilidad: ', text: 'Permite almacenar datos en un formato de documentos, lo que facilita el manejo de datos.'},
        {name:'Alta disponibilidad: ', text: 'Ofrece una alta disponibilidad gracias a sus características de replicación y balanceo de carga.'},
        {name:'Integración: ', text: 'Es compatible con una amplia variedad de lenguajes de programación y plataformas.'}
      ]
    },
    {
      name: 'Scss', 
      logoSrc: scssLogo, 
      advantages: 
      [
        {name:'Variables: ', text: 'SCSS permite utilizar variables para almacenar valores como colores, tamaños y fuentes.'},
        {name:'Anidamiento: ', text: 'SCSS permite anidar las reglas CSS, lo que mejora la legibilidad del código y reduce la redundancia.'},
        {name:'Mixins: ', text: 'SCSS permite crear "mezclas" de reglas CSS, lo que facilita la reutilización de código.'},
        {name:'Funciones matemáticas: ', text: 'SCSS permite utilizar funciones matemáticas para calcular valores.'}
      ]
    }
]