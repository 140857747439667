import React, { useState, useEffect } from 'react';
import './Poster.scss';
import arriba from '../../Assets/images/arriba.png'
import abajo from '../../Assets/images/centro.png'
import derecha from '../../Assets/images/derecha.png'
import izquierda from '../../Assets/images/izquierda.png'
import icono from '../../Assets/images/icono.png'
import arrow from '../../Assets/images/arrow.png'


function Poster(props) {

  const [posterStyle, setPosterStyle] = useState({});
  const [animationForArribaElement, setanimationForArribaElement] = useState('el ');
  const [animationForAbajoElement, setanimationForAbajoElement] = useState('el ');
  const [animationForIzquierdaElement, setanimationForIzquierdaElement] = useState('el ');
  const [animationForDerechaElement, setanimationForDerechaElement] = useState('el ');
  const [animationForLogo, setAnimationForLogo] = useState('logo');
  const [animationTitle, setAnimationTitle] = useState('');
  const [animationStartArrow, setAnimationStartArrow] = useState('hide');

  useEffect(()=>{
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
    setanimationForArribaElement('go-form-logo-arriba el ');
    setanimationForAbajoElement('go-form-logo-abajo el ');
    setanimationForIzquierdaElement('go-form-logo-izquierda el ');
    setanimationForDerechaElement('go-form-logo-derecha el ');
    setTimeout(()=>{
      hideElements();
      showLogo();
    },3000)
  },[]);

  return (
    <div className="component-poster" style={posterStyle}>
      <img src={arriba} alt="arriba" className={animationForArribaElement+' arriba'}/>
      <img src={abajo} alt="abajo" className={animationForAbajoElement+' abajo'}/>
      <img src={izquierda} alt="izquierda" className={animationForIzquierdaElement+' izquierda'}/>
      <img src={derecha} alt="derecha" className={animationForDerechaElement+' derecha'}/>

      <img src={icono} alt="Logo" className={animationForLogo}/>
      <h1  className={animationTitle+' title'}>Stroom</h1>
      <h1  className={animationTitle+' subTitle'}>Diseño y desarrollo web.</h1>
      <img src={arrow} alt="arrow" onClick={start} className={animationStartArrow+' startArrow'}/>
    </div>
  );

  function start() {
    props.transitionToHome();
    setPosterStyle({
      transition: '2s',
      opacity: 0,
      height: '0px'
    });
    // setAnimationForLogo('hide');
    // setAnimationStartArrow('hide');
    // setAnimationTitle('hide');
  }

  function hideElements(){
    setanimationForArribaElement('reduce go-form-logo-arriba el ');
    setanimationForAbajoElement('reduce go-form-logo-abajo el ');
    setanimationForIzquierdaElement('reduce go-form-logo-izquierda el ');
    setanimationForDerechaElement('reduce go-form-logo-derecha el ');
  }

  function showLogo(){
    setTimeout(()=>{
      setAnimationForLogo('logo appear');
    },500)
    setTimeout(()=>{
      setAnimationTitle('appearTransparent');
      setAnimationStartArrow('appearDisplay');
    },1500)
  }
}

export default Poster;