import React from 'react';
import './Resume.scss';

function Resume({order, totalPrice, orderNotCompleted}) {
   

    return (
        <>
            <div className="resume">
                <h2><b>Presupuesto</b></h2>
                <div className="resume-text">
                    <h3><b>Plantilla: {`${order.design.price}€`}</b></h3>
                    <h4>({order.design.name})</h4>

                    <h3><b>Mantenimiento: {`${order.maintenance.price}€`}</b></h3>
                    <h4> ({order.maintenance.name})</h4>
                    {!!order.extras.length && <div>
                        <h3><b>Extras: {order.extras.reduce((acc, act)=> (act.price*act.units)+acc, 0)}€</b></h3>
                        <ul>
                            {order.extras.map((extra, i) => <li key={i}>{`${extra.units} x ${extra.name} :`}<b>{`${extra.price*extra.units}€`}</b></li>)}
                        </ul>
                    </div>}
                </div>
                <div className="capsule first-month"><b>Pago inicial: </b> {totalPrice}€</div>
                <div className="capsule rest-of-months"><b>Resto de meses: </b> {order['maintenance'].price}€</div>
            </div>
        </>
    );

 
}

export default Resume;