import React, {useState, useEffect} from 'react';
import './Estimation.scss';
import Resume from './Resume/Resume';
import { EstimationSteps } from '../../Components/EstimationSteps/EstimationSteps.jsx';
import preImage from "../../Assets/images/left-arrow.png";
import nextImage from "../../Assets/images/right-arrow.png";
import StroomCard from '../../Components/StroomCard/StroomCard'
import StroomToggleButton from '../../Components/StroomToggleButton/StroomToggleButton'
import {newOrder} from '../../services/order-service';
import { useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {maintenance, extras, designPackages} from '../../services/local/rates';
import {track, page} from '../../services/segment-service';

function Estimation() {
    const history = useHistory();

    const [currentStep, setcurrentStep] = useState(0);
    const [order, setorder] = useState({extras:[]});
    const [totalPrice, settotalPrice] = useState(0);
    const [name, setname] = useState(null);
    const [email, setemail] = useState(null);
    const [isCustomSelection, setisCustomSelection] = useState(false);
    
    //Maneja la vista para cambiarla una vez se haya guardado en back el pedido
    const [orderNotCompleted, setorderNotCompleted] = useState(true);

    const [showOrderError, setshowOrderError] = useState(false);
    const [refreshPrices, setrefreshPrices] = useState(false);



    useEffect(() => {
        page('Estimation')
    },[])

    useEffect(() => {
        if(currentStep===3){
            if(isCustomSelection){
                track('Presupuesto visualizado', {
                    design: "Custom", 
                })
            }else{
                track('Presupuesto visualizado', {
                    design: order?.design?.id, 
                    maintenance: order?.maintenance?.id, 
                    estras: order?.extras?.map(extra => extra?.code), 
                    totalPrice
                })
            }
        }
    },[currentStep, order, totalPrice])

    useEffect(() => {
        let total = 0;
        total += order.extras ? order.extras.reduce((acc, act)=> (act.price*act.units)+acc, 0) : 0;
        total += order.maintenance ? order.maintenance.price : 0;
        total += order.design ? order.design.price : 0;
        settotalPrice(total)
    }, [order, refreshPrices])

    const steps = ['Diseño','Extras','Mantenimiento'];

    return (
        <section className="view-estimation">
            {/* STEPPER */}
            {orderNotCompleted&&<EstimationSteps currentStep={currentStep} steps={steps}></EstimationSteps>}

            {/* MOBILE BUTTONS */}
            {!isCustomSelection && orderNotCompleted && currentStep !== 0 &&
                <button className="mobile-prev-button mobile-button" onClick={()=> prevStep()}>
                    <img src={preImage} alt=""/>
                </button>
            }
            {isCustomSelection && currentStep === 3 &&
                <button className="mobile-prev-button mobile-button" onClick={()=> {setcurrentStep(0); setisCustomSelection(false)}}>
                    <img src={preImage} alt=""/>
                </button>
            }
            {orderNotCompleted && currentStep < steps.length && 
                <button disabled={isNextButtonDisabled()} className="mobile-next-button mobile-button" onClick={()=> nextStep()}>
                    <img src={nextImage} alt=""/>
                </button>
            }

            {/* DISEÑO */}
            {orderNotCompleted && currentStep===0 && 
            <div className="design step-view">
                <h1>Diseño</h1>
                <div className="design-packages">
                    {designPackages.map((design, i) =>
                    <StroomCard 
                        isSelected={order?.design?.name === design?.name}
                        key={i}
                        onClickCard={()=> design?.redirection === "custom-rate" ? onCustomRateSelected() : selectInitialDesign(design)}
                        color={i}
                        name={design.name}
                        price={design.price}
                        frequence={design.frequeance}
                        text={design.text}
                        content={design.components}
                        hideFooter={true}
                    >
                    </StroomCard>
                )}
                </div>
            </div>}

            {/* EXTRAS */}
            {orderNotCompleted &&currentStep===1 && <div className="extras step-view">
                <h1>Extras</h1>
                {extras.map(extra =>
                    <div className="extras-option" key={extra.name}>
                        <p>{extra.name} </p>
                        <StroomToggleButton 
                            class="stroom-toggle-button-flex" 
                            defaultChecked={order.extras.some(orderedExtra => orderedExtra.code === extra.code)} 
                            extraSelected={() => extraSelected(extra)}
                            currentUnits={order.extras.find(orderedExtra => orderedExtra.code === extra.code)?.units}
                            setNumberOfItems={(number) => order.extras.find(orderedExtra => orderedExtra.code === extra.code).units = number}
                            isDisabled={extra.disabled}
                        >
                        </StroomToggleButton>
                    </div>
                )}
            </div>}

            {/* MANTENIMIENTO */}
            {orderNotCompleted && currentStep===2 && <div className="maintenance step-view">
                <h1>Mantenimiento</h1>
                <div className="maintenance-cards">
                    {maintenance.map((maintenanceElem, i) => 
                    <StroomCard 
                        isSelected={order?.maintenance?.name === maintenanceElem?.name}
                        key={i}
                        onClickCard={()=> selectMaintenanceCard(maintenanceElem)}
                        color={i}
                        name={maintenanceElem.name}
                        price={maintenanceElem.price}
                        frequence={maintenanceElem.frequence}
                        content={maintenanceElem.content}
                        maintenanceHours={maintenanceElem.maintenanceHours}
                        text={maintenanceElem.text}
                    >
                    </StroomCard>
                )}
                </div>
            </div>}

            {/* PRESUPUESTO */}
            {currentStep===3 && <div className="send-data step-view">
                {orderNotCompleted && <div className="order-form">
                    {isCustomSelection ? 
                        <h3 className="custom-message">Rellene los datos y  nos pondremos en contacto con usted para la toma de requisitos</h3>
                    : 
                        <h1>Envía tu solicitud</h1>
                    }

                    <div className="field-data">
                        <input id="input" type="text" className="Input-text" onKeyUp={(ev) => setname(ev.currentTarget.value)} placeholder="Nombre"/>
                        <label htmlFor="input" className="Input-label">Nombre:</label>
                    </div>
                    <div className="field-data">
                        <input id="email" type="email" className="Input-text" onKeyUp={(ev) => setemail(ev.currentTarget.value)} placeholder="Email"/>
                        <label htmlFor="email" className="Input-label">Email:</label>
                    </div>
                </div>}
                
                {!orderNotCompleted && <h3 className="order-confirm-message">Hemos registrado tu solicitud, en breves nos pondremos en contacto contigo para foamalizar la operación.</h3>}
                {!isCustomSelection&&<Resume order={order} totalPrice={totalPrice} orderNotCompleted={orderNotCompleted}></Resume>}
                {/* BOTON SOLICITAR MOVIL */}
                {orderNotCompleted && currentStep === steps.length && 
                <div className="mobile-button-bar">
                   <button disabled={!name || name?.length<4 || !email ||email?.length<6} className="shadow-button-sliding final-button" onClick={()=> finishEstimation()}>Solicitar</button>
                </div>}
            </div>}

            {/* BOTONES DESKTOP */}
            {!isCustomSelection && currentStep !== 0 && orderNotCompleted && <button className="no-mobile-elem shadow-button-sliding prev-button" onClick={()=> prevStep()}>Atrás</button>}
            {isCustomSelection && currentStep === 3 && <button className="no-mobile-elem shadow-button-sliding prev-button" onClick={()=> {setcurrentStep(0); setisCustomSelection(false)}}>Atrás</button>}
            {currentStep < steps.length && orderNotCompleted && <button disabled={isNextButtonDisabled()} className="no-mobile-elem shadow-button-sliding next-button" onClick={()=> nextStep()}>Siguiente</button>}
            {currentStep === steps.length && orderNotCompleted && <button disabled={!name || name?.length<4 || !email ||email?.length<6} className="no-mobile-elem shadow-button-sliding request-button" onClick={()=> finishEstimation()}>Solicitar</button>}

            {!orderNotCompleted && <button className="shadow-button-sliding" onClick={()=> history.push('/')}>Entendido</button>}

            <Snackbar
                open={showOrderError}
                autoHideDuration={30000}
                onClose={() => history.push('/')}
                message="Error al realizar el pedido"
                action= {(
                    <React.Fragment>
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => history.push('/')}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </React.Fragment>
                  )}
            />

        </section>
    );

    function onCustomRateSelected(){
        setisCustomSelection(true);
        setcurrentStep(3);
    }

    function isNextButtonDisabled() {
        const dictionary = {0: !order.design, 1: false, 2: !order.maintenance}
        return dictionary[currentStep]
    }

    function nextStep() {
        if(currentStep !== steps.length){
            setcurrentStep(currentStep+1);
        }

        //Si lleva al ultimo paso, refresca los precios
        if(currentStep+1 === steps.length){
            setrefreshPrices(prev=> !prev)
        }
    }
    
    function prevStep() {
        if(currentStep !== 0){
            setcurrentStep(currentStep-1);
        }
    }
    
    function finishEstimation() {
        const orderToSend = {
            name: name,
            email: email,
            total_price: totalPrice,
            template_id: order.design.id,
            maintenance_id: order.maintenance.id,
            extras: order.extras.map(extra => extra.code)
        }
        newOrder(orderToSend).then(isFetchOK => {setorderNotCompleted(!isFetchOK); setshowOrderError(!isFetchOK)});
    }

    function selectMaintenanceCard(card){
        const oderCopy = Object.assign({}, order)
        oderCopy.maintenance = card;
        setorder(oderCopy);
    }

    function selectInitialDesign(design){
        const oderCopy = Object.assign({}, order)
        oderCopy.design = design;
        setorder(oderCopy);
    }

    function extraSelected(extra){
        const isOnOrder = order.extras.includes(extra)
        order.extras = isOnOrder ? order.extras.filter(oldExtra => oldExtra.code!==extra.code) : [...order.extras, extra] 
    }
}

export default Estimation;