import React, { useEffect } from 'react';
import './StroomSlashButton.scss';

function StroomSlashButton({buttontext, onClickFunction, otherStyle}) {

    useEffect( ()=>{
    },[])

    return (
        <>
           <div className={otherStyle || 'stroom-slash-button'} onClick={onClickFunction}>
                <p className='orbitron'>{buttontext}</p>
            </div>
        </>
    )
}

export default StroomSlashButton;
