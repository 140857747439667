import { Tooltip } from '@mui/material';
import React from 'react';
import './StroomCard.scss';

function StroomCard({color, name, price, frequence, maintenanceHours, content, text, onClickCard, isSelected}) {

  return (
    <Tooltip title={text}>
      <div onClick={onClickCard} className={'stroom-card'}>
          <div className="img-container">
            <div className="inner-skew">
              <div className={`color-div color-${color}`}>
                <h2>{name}</h2>{frequence&&<p>({frequence})</p>}
                {maintenanceHours&&<div className="card-stats">
                  <div className="stat">
                    <div className="value">{maintenanceHours}h</div>
                    <div className="type">mantenimiento</div>
                  </div>
                  <div className="stat">
                    <div className="value">{price}€</div>
                    <div className="type">/mes</div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        <div className={isSelected ?  "stroom-card-selected text-container" : "text-container"}>
          {maintenanceHours ? <h3>{text}</h3> : <h3>{price}€</h3>}
          <div>
            <ul>{content?.map((option, i) => <li key={i}>{option}</li>)}</ul>
          </div>
        </div>
      </div>
    </Tooltip> );
 
}

export default StroomCard;
