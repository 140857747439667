import React, { useEffect, useState } from 'react';
import StroomInputNumber from '../StroomInputNumber/StroomInputNumber';
import './StroomToggleButton.scss';

function StroomToggleButton({extraSelected, defaultChecked, setNumberOfItems, isDisabled, currentUnits, disableCounter}) {

    const [isSelected, setisSelected] = useState(false)

    
    useEffect( ()=>{
        setisSelected(defaultChecked)
    },[defaultChecked])

    const switchHandler = () => {
        setisSelected((old) => !old)
        extraSelected()
    }


    return (
        <>
            <input id="chck" type="checkbox"/>
            <label htmlFor="chck" className={isSelected ?  "check-trail swhitchButtonSelected" : "check-trail"} onClick={()=> {if (!isDisabled) switchHandler()}}>
                <span className="check-handler"></span>
            </label>
            {!disableCounter&&isSelected&&<StroomInputNumber currentUnits={currentUnits} setNumber={(number) => setNumberOfItems(number)} disabled={isDisabled}></StroomInputNumber>}
        </>
    )
}

export default StroomToggleButton;
