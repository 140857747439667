import React from 'react';
import { useHistory } from "react-router-dom";


function NotFound() {
  const history = useHistory();

  return (
    <section>
      <h1>La página no existe, por favor, regrese a la página principal.</h1>
      <button onClick={() =>history.push('/')}>Go home</button>
    </section>
  );
}
 
export default NotFound;