import React from 'react';
import "./PrivacyPolicy.scss";

function PrivacyPolicy() {
 
  return (
    <section className='view-privacy-policy'>

      <div className="policy">
        <h1>Política de privacidad de Stroom</h1>
        <p className="main-paragraph">Stroom (en adelante, "nosotros" o "nuestra") valora su privacidad y se compromete a proteger la información personal que nos proporciona. Esta política de privacidad establece cómo recopilamos, usamos, compartimos y protegemos su información personal al utilizar nuestro sitio web, servicios y productos (colectivamente, los "Servicios").</p>
        <h2>Recopilación de información personal</h2>
        <p>Podemos recopilar información personal sobre usted, incluyendo, pero no limitado a, su nombre, dirección de correo electrónico, dirección postal y número de teléfono, cuando se registra para utilizar nuestros Servicios, se suscribe a un boletín o participa en otras actividades en línea. También podemos recopilar información automáticamente, como su dirección IP, información sobre su dispositivo y su actividad de navegación en nuestro sitio web. Utilizamos esta información para mejorar la experiencia de usuario y entender cómo se utilizan nuestros Servicios.</p>
        <h2>Uso de información personal</h2>
        <p>Utilizamos la información personal recopilada para proporcionar y mejorar nuestros Servicios, comunicarnos con usted, responder a sus preguntas y solicitudes, personalizar su experiencia en línea y cumplir con cualquier otro propósito para el cual se recopiló la información.</p>
        <h2>Compartir información personal</h2>
        <p>No vendemos ni compartimos su información personal con terceros a menos que sea necesario para proporcionar nuestros Servicios o cumplir con las leyes aplicables.</p>
      </div>
      <div className="policy">
        <h1>Política de cookies de Stroom</h1>
        <p className="main-paragraph">Stroom (en adelante, "nosotros" o "nuestra") valora su privacidad y se compromete a proteger la información recopilada a través de las cookies utilizadas en nuestro sitio web. Esta política de cookies establece cómo recopilamos, usamos y protegemos la información recopilada a través de las cookies al utilizar nuestro sitio web y servicios.</p>
        <h2>¿Qué son las cookies?</h2>
        <p>Las cookies son pequeños archivos de texto que se almacenan en su dispositivo al visitar un sitio web. Estos archivos permiten que el sitio web recuerde su actividad y preferencias, y proporcionen una experiencia de usuario personalizada.</p>
        <h2>¿Cómo utilizamos las cookies?</h2>
        <p>Utilizamos las cookies para mejorar la experiencia de usuario en nuestro sitio web, entender cómo se utilizan nuestros servicios y para fines de análisis y estadísticas. Por ejemplo, las cookies pueden ayudar a recordar sus preferencias de idioma o permitirle navegar de forma más eficiente.</p>
        <h2>¿Qué tipos de cookies utilizamos?</h2>
        <p>Utilizamos cookies de sesión y cookies persistentes. Las cookies de sesión se eliminan automáticamente cuando cierra su navegador, mientras que las cookies persistentes permanecen en su dispositivo hasta que expire el tiempo de vida o se eliminen manualmente.</p>
        <h2>¿Cómo puedo controlar las cookies?</h2>
        <p>Puede ajustar sus preferencias de cookies en su navegador web en cualquier momento. También puede eliminar las cookies almacenadas en su dispositivo a través de las opciones de su navegador.</p>
        <h2>¿Compartimos la información recopilada a través de las cookies con terceros?</h2>
        <p>No compartimos la información recopilada a través de las cookies con terceros a menos que sea necesario para proporcionar nuestros servicios o cumplir con las leyes aplicables.</p>
        <h2>Cambios a esta política de cookies</h2>
        <p>Nos reservamos el derecho a modificar esta política de cookies en cualquier momento. Le notificaremos sobre cualquier cambio mediante la publicación de la nueva política en nuestro sitio web.</p>
      </div>
    </section>
  );
}
 
export default PrivacyPolicy;