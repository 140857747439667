import React from "react";
import "./Branding.scss";
import { brandingScopes } from "../../services/local/branding";

function Branding() {
    return (
        <section className='view-branding'>
            <h1>Branding para Tu Empresa</h1>
            <p>En Stroom ofrecemos soluciones de branding completas para que tu empresa tenga una presencia única y reconocida en el mercado.</p>

            <div className='scope-cards'>
                {brandingScopes.map((scope, i) => (
                    <div key={i} className='scope-card'>
                        <h2>{scope.name}</h2>
                        <p>{scope.text}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Branding;
