import { Frequencies } from '../../Assets/enums/frequencies.ts';

// const designFeatures = [
//         { name: "Alojamiento web", pricePerUnit: 0, maxPrice: null, minUnits: 0, maxUnits: 1, multiplier: 'Depende del servicio de alojamiento' },
//         { name: "Dominio web", pricePerUnit: 0, maxPrice: null, minUnits: 0, maxUnits: 1, multiplier: 'Depende del dominio' },
//         { name: "Logo personalizado", pricePerUnit: 200, maxPrice: null, minUnits: 0, maxUnits: 1, multiplier: '' },
//         { name: "Secciones", pricePerUnit: 80, maxPrice: null, minUnits: 0, maxUnits: 15, multiplier: '' },
//         { name: "Redacción de contenidos", pricePerUnit: 40, maxPrice: null, minUnits: 0, maxUnits: 100, multiplier: 'Por cada sección y alto de pantalla' },
//         { name: "Posicionamiento (SEO)", pricePerUnit: 700, maxPrice: null, minUnits: 0, maxUnits: 1, multiplier: '' }
// ];

// const advanceFunctions = [
//     { name: "Login + registro", pricePerUnit: 500, maxPrice: null, minUnits: 0, maxUnits: 10, multiplier: '' },
//     { name: "Chat interno", pricePerUnit: 500, maxPrice: null, minUnits: 0, maxUnits: 10, multiplier: '' },
//     { name: "Backend administración", pricePerUnit: 500, maxPrice: null, minUnits: 0, maxUnits: 1, multiplier: '' },
//     { name: "Blog de noticias actualizable por el cliente", pricePerUnit: 200, maxPrice: null, minUnits: 0, maxUnits: 10, multiplier: 'Más Login y Backend de administración' },
//     { name: "Blog de noticias administrado por Stroom", pricePerUnit: 60, maxPrice: null, minUnits: 0, maxUnits: 10, multiplier: '' },
// ]

export const designPackages = [
    { 
        id: 'landing',
        name: 'Landing', 
        price: 300, 
        text: 'Una página que capte al atención de tus clientes',
        components: ['Página de una vista.', 'Banner.', 'Logo y colores personalizados.', 'Información sobre tu producto.', 'Informacion de contacto.'],
        frequence: Frequencies.unico
    },
    { 
        id: 'basic',
        name: 'Basic', 
        price: 450, 
        text: 'Una página personalizada con una pagina de contacto dedicada y footer.',
        components: ['Todo lo incluido en Landing.', 'Vista independiente de contacto.', 'Footer.'],
        frequence: Frequencies.unico
    },
    { 
        id: 'premium',
        name: 'Premium', 
        price: 750, 
        text: 'Una página web con un menú personalizado que consta de pagina principal, contacto y 3 secciones adicionales.',
        components: ['Todo lo incluido en Basic.', 'Hasta 3 vistas adicionales (total 5).', 'Menú.'],
        frequence: Frequencies.unico
    },
    { 
        id: 'custom',
        name: 'Custom', 
        price: "- -", 
        text: 'Una página web profesional a medida.',
        components: ['Plantilla totalmente personalizada', 'Requerimientos Ilimitados', 'Toma de requisitos con cliente', 'Todos los estilos a medida'],
        frequence: Frequencies.unico,
        redirection: "custom-rate"
    },
]


export const maintenance = [
    {   
        id: 'basic',
        name: "Basic", 
        price: 120, 
        frequence: Frequencies.mensual, 
        duration: 'Sin permanencia',
        maintenanceHours: 2,
        content: ['Atención telefónica directa'], 
        text: 'Recomendado para landings estáticas' 
    },
    {   
        id: 'plus',
        name: "Plus", 
        price: 220, 
        frequence: Frequencies.mensual, 
        duration: 'Sin permanencia',
        maintenanceHours: 4,
        content: ['Atención telefónica directa', 'Asesoramiento sobre mejoras'], 
        text: 'Recomendado para web básicas, con pocos cambios' 
    },
    {   
        id: 'premium',
        name: "Premium ", 
        price: 400, 
        frequence: Frequencies.mensual, 
        duration: 'Sin permanencia',
        maintenanceHours: 8,
        content: ['Atención telefónica directa', 'Asesoramiento del equipo de UX', 'Mejoras Activas (rendimiento, codigo...)'], 
        text: 'Recomendado para una web más compleja' 
    },
]

export const extras = [
    { name: "Idioma Extra", code: 'extraLanguage', price: 30, maxPrice: 500, units: 0, minUnits: 0, maxUnits: 5, disabled: false},
    { name: "Slider imagenes", code: 'imageSlider', price: 40, maxPrice: null, units: 0, minUnits: 0, maxUnits: 30, disabled: false},
    { name: "Galeria de videos", code: 'videoSlider', price: 80, maxPrice: null, units: 0, minUnits: 0, maxUnits: 30, disabled: false},
    { name: "Video fondo de web", code: 'videoBackground', price: 40, maxPrice: null, units: 0, minUnits: 0, maxUnits: 30, disabled: false},
    { name: "Formulario (3 campos de texto)", code: 'form3fields', price: 50, maxPrice: null, units: 0, minUnits: 0, maxUnits: 10, disabled: false},
    { name: "Campo extra formulario", code: 'extraFormField', price: 20, maxPrice: null, units: 0, minUnits: 0, maxUnits: 50, disabled: false},
    { name: "Vista con adaptación a móvil", code: 'mobileView', price: 60, maxPrice: null, units: 0, minUnits: 0, maxUnits: 5, disabled: false},
    { name: "Vista adicional", code: 'extraSection', price: 90, maxPrice: null, units: 0, minUnits: 0, maxUnits: 5, disabled: false}
]